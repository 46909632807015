import { Paper, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PercentButton } from '@percent/admin-dashboard/common/components'
import styles from './BankDetailsHeader.module.scss'
import { StatusBadge } from '@percent/admin-dashboard/app/bankDetails/statusBadge/StatusBadge'
import { BankDetailsHeaderProps } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsHeader/BankDetailsHeader.types'

export function BankDetailsHeader({
  organizationName,
  status,
  setDialogState,
  setFormStatus,
  disabledApprove
}: BankDetailsHeaderProps) {
  const { t } = useTranslation()

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.container}>
      <Grid item xs={12} className={styles.nonprofitDetail}>
        <div className={styles.ellipsisParent}>
          <div className={styles.ellipsisContainer}>
            <div className={styles.ellipsisText}>
              <Typography variant="h6" className={styles.heading}>
                {organizationName}
              </Typography>
              <StatusBadge status={status} />
            </div>
            {status === 'pending' && (
              <div className={styles.buttonsContainer}>
                <PercentButton
                  title={t('button.approve')}
                  className={styles.approveButton}
                  disabled={disabledApprove}
                  testId="approve-button"
                  onClick={() => {
                    setDialogState(true)
                    setFormStatus('approved')
                  }}
                />
                <PercentButton
                  title={t('button.reject')}
                  testId="reject-button"
                  onClick={() => {
                    setDialogState(true)
                    setFormStatus('rejected')
                  }}
                  className={styles.rejectButton}
                />
              </div>
            )}
            {status === 'approved' && (
              <div className={styles.buttonsContainer}>
                <PercentButton
                  title={t('button.remove')}
                  testId="remove-button"
                  onClick={() => {
                    setDialogState(true)
                    setFormStatus('deleted')
                  }}
                  className={styles.rejectButton}
                />
              </div>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  )
}
