import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SelectOption } from '../../../../../../libs/shared/ui-lemonade/src/components/select/option.types'
import { MemberListTableAccountDetails } from '../MemberListTable.types'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import { useMutation, useQuery } from '@percent/admin-dashboard/common/hooks'
import { Dialog, DialogCreateBody } from '@percent/admin-dashboard/common/components'
import { Alert, Badge, FormField, Select } from '@percent/lemonade'
import { RoleResponse } from '@percent/admin-dashboard/api/actions/iam/iamService.types'

const validationSchema = Yup.object().shape({
  roleId: Yup.string().required('Required')
})

type AssignRoleDialogProps = {
  open: boolean
  onClose: () => void
  refresh: () => void
  partnerId: string
  account?: MemberListTableAccountDetails
}

export function AssignRoleDialog({ open = false, onClose, refresh, partnerId, account }: AssignRoleDialogProps) {
  const { iamService } = useServices()
  const addToast = useToast()

  const [{ data: rolesForThisPartner, isLoading: loadingRoles }] = useQuery(iamService.getRolesForPartner, {
    partnerId
  })

  const { t } = useTranslation()

  const userCurrentRole = useMemo(() => {
    const roleFromData = rolesForThisPartner?.roles.find(
      (role: RoleResponse) => role.displayName === account?.currentRoleName
    )

    return {
      label: roleFromData?.displayName,
      value: roleFromData?.id
    }
  }, [account?.currentRoleName, rolesForThisPartner?.roles])

  const [{ errorMessage, isLoading, success }, { apiFunc }] = useMutation(iamService.setRoleForAccount, () => {
    refresh()
    onClose()
  })
  const { values, errors, setValues, handleSubmit, touched } = useFormik({
    initialValues: {
      roleId: userCurrentRole.value || ''
    },
    validationSchema,
    onSubmit: ({ roleId }: { roleId: string }) => {
      apiFunc({
        accountId: account?.id ?? '',
        role: roleId
      })
    }
  })

  useEffect(() => {
    if (success) {
      addToast(t('typography.roleChangedSuccessfully'), 'success')
    }
  }, [success, addToast, t])

  useEffect(() => {
    setValues({ roleId: userCurrentRole?.value })
    setSelectedValue(userCurrentRole)
  }, [setValues, userCurrentRole])

  const [selectedValue, setSelectedValue] = useState<SelectOption>({
    label: '',
    value: '',
    description: ''
  })

  const selectOptions = useMemo(
    () =>
      rolesForThisPartner?.roles?.map((role: RoleResponse) => ({
        label: role.displayName,
        value: role.id,
        disabled: role.displayName === account?.currentRoleName
      })),
    [account?.currentRoleName, rolesForThisPartner]
  )

  return (
    <Dialog openModal={open} onClose={onClose} headerTitle={t('typography.setUserRole')}>
      <DialogCreateBody
        loading={isLoading || loadingRoles}
        errorMessage={errorMessage}
        handleSubmit={handleSubmit}
        buttonTitle={t('button.setRoleForUser')}
        testId="create-member-button"
      >
        <Stack gap="24px">
          <FormField
            label={t('typography.role')}
            status={touched.roleId && errors.roleId ? 'danger' : 'default'}
            statusMessage={errors.roleId}
            data-testid="role"
          >
            <Select
              name="role"
              placeholder={t('form.selectRole')}
              onChange={e => {
                setValues({ ...values, roleId: e.value })
                setSelectedValue({
                  label: e.label,
                  value: e.value,
                  description: e.description
                })
              }}
              options={selectOptions || []}
              defaultValue={selectedValue}
            />
          </FormField>

          {values.roleId && (
            <Alert variant="info" title={t('typography.changeSummary')} className="mt-4">
              {account?.email}
              <br />
              <Badge variant="default">{account?.currentRoleName}</Badge> &#8594;{' '}
              <Badge variant="default">{selectedValue.label}</Badge>
            </Alert>
          )}
        </Stack>
      </DialogCreateBody>
    </Dialog>
  )
}
