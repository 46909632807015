import { Capabilities } from '@percent/admin-dashboard/api/capabilities'
import { adminService } from './admin/admin'
import { agentVerificationService } from './agentVerification/agentVerification'
import { authService } from './auth/auth'
import { activityCheckService } from './activityCheck/activityCheck'
import { milestonesService } from './milestones/milestonesService'
import { eligibilityService } from './eligibility/eligibility'
import { registriesService } from './registries/registries'
import { disbursementsService } from './disbursements/disbursements'
import { foundationsService } from './foundations/foundations'
import { reportingService } from './reporting/reportingService'
import { currenciesService } from './currencies/currencies'
import { bankDetailsService } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails'
import { donationRequestsService } from './donationRequests/donationRequests'
import { donationsService } from './donations/donations'
import { countriesService } from './countries/countriesService'
import { iamService } from '@percent/admin-dashboard/api/actions/iam/iamService'
import { donationMatchRequestsService } from './donationMatchRequests/donationMatchRequests'
import { autoRejectionListService } from './autoRejectionList/autoRejectionList'

export const services = (capabilities: Capabilities) => ({
  iamService: iamService(capabilities),
  adminService: adminService(capabilities),
  authService: authService(capabilities),
  agentVerificationService: agentVerificationService(capabilities),
  activityCheckService: activityCheckService(capabilities),
  milestonesService: milestonesService(capabilities),
  eligibilityService: eligibilityService(capabilities),
  registriesService: registriesService(capabilities),
  disbursementsService: disbursementsService(capabilities),
  foundationsService: foundationsService(capabilities),
  reportingService: reportingService(capabilities),
  currenciesService: currenciesService(capabilities),
  bankDetailsService: bankDetailsService(capabilities),
  donationRequestsService: donationRequestsService(capabilities),
  donationsService: donationsService(capabilities),
  countriesService: countriesService(capabilities),
  donationMatchingRequestService: donationMatchRequestsService(capabilities),
  autoRejectionListService: autoRejectionListService(capabilities)
})

export type Services = ReturnType<typeof services>
