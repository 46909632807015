import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, CardDashboard, Spacer } from '@percent/lemonade'
import { ErrorView, Loader } from '@percent/admin-dashboard/common/components'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { Organisation, PaymentMethod } from '@percent/admin-dashboard/api/types'
import { BankDetailStatus } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsFormDialog/BankDetailsFormDialog.types'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import styles from './PaymentDetails.module.scss'
import { BankDetailsView } from './BankDetailsView'
import { CardView } from './CardView'
import { PaymentTypeWithButton } from './PaymentTypeWithButton'
import { AddBankDetailsModal } from './AddBankDetailsModal'
import { RemoveBankDetailsModal } from './RemoveBankDetailsModal'

export function PaymentDetails({
  organization,
  paymentMethod,
  paymentMethodRefresh
}: {
  organization: Organisation
  paymentMethod?: PaymentMethod
  paymentMethodRefresh: VoidFunction
}) {
  const [openAddBankDetailsModal, setOpenAddBankDetailsModal] = useState(false)
  const [openRemoveBankDetailsModal, setOpenRemoveBankDetailsModal] = useState(false)

  const { t } = useTranslation()
  const { bankDetailsFeatureFlag } = useFeatureFlag()
  const { bankDetailsService } = useServices()

  const [{ data: bankDetailsData, isLoading: bankDetailsLoading, errorMessage: bankDetailsError }, { refresh }] =
    useQuery(bankDetailsService.getBankDetails, { organisationId: organization.id }, { skip: !organization.claimedAt })

  const handleRemoveBankDetailsModal = () => {
    setOpenRemoveBankDetailsModal(prevState => !prevState)
  }

  const handleAddBankDetailsModal = () => {
    setOpenAddBankDetailsModal(prevState => !prevState)
  }

  if (bankDetailsLoading) {
    return <Loader />
  }

  if (bankDetailsError) {
    return <ErrorView errorMessage={bankDetailsError} />
  }

  const paymentDetailsComponents: JSX.Element[] = []

  if (paymentMethod?.method === 'card') {
    paymentDetailsComponents.push(<CardView paymentMethod={paymentMethod} refresh={paymentMethodRefresh} />)
  }

  if (paymentMethod?.method === 'cheque') {
    paymentDetailsComponents.push(
      <PaymentTypeWithButton
        title={t('typography.paymentMethod')}
        text={t('typography.cheque')}
        paymentMethod={paymentMethod}
        refresh={paymentMethodRefresh}
      />
    )
  }

  if (
    organization.claimedAt &&
    (bankDetailsData?.status === BankDetailStatus.APPROVED ||
      bankDetailsData?.status === BankDetailStatus.PENDING ||
      !paymentDetailsComponents.length)
  ) {
    paymentDetailsComponents.length &&
      paymentDetailsComponents.push(<div className={styles.spacerWithLine} />, <Spacer size={6} axis="vertical" />)

    paymentDetailsComponents.push(
      <BankDetailsView
        organization={organization}
        paymentMethod={paymentMethod}
        paymentMethodRefresh={paymentMethodRefresh}
        bankDetailsData={bankDetailsData}
        bankDetailsFeatureFlag={bankDetailsFeatureFlag}
      />
    )
  }

  if (!paymentDetailsComponents.length) {
    paymentDetailsComponents.push(
      <PaymentTypeWithButton
        title={t('typography.paymentMethod')}
        text={t('typography.noneSupplied')}
        paymentMethod={paymentMethod}
        refresh={paymentMethodRefresh}
      />
    )
  }

  return (
    <>
      <CardDashboard
        title={t('typography.paymentDetails')}
        action={
          bankDetailsFeatureFlag &&
          organization.claimedAt &&
          (bankDetailsData?.status === BankDetailStatus.APPROVED ? (
            <Button onPress={handleRemoveBankDetailsModal} variant="secondary" data-testId="bank-details-action-button">
              {t('typography.removeBankDetails')}
            </Button>
          ) : !bankDetailsData?.bankAccountDetails || bankDetailsData?.status === BankDetailStatus.REJECTED ? (
            <Button onPress={handleAddBankDetailsModal} variant="secondary" data-testId="bank-details-action-button">
              {t('typography.addBankDetails')}
            </Button>
          ) : null)
        }
      >
        {paymentDetailsComponents}
      </CardDashboard>
      <Spacer size={6} axis="vertical" />
      {organization.claimedAt && (
        <AddBankDetailsModal
          open={openAddBankDetailsModal}
          onClose={handleAddBankDetailsModal}
          refresh={refresh}
          bankDetails={bankDetailsData}
        />
      )}
      <RemoveBankDetailsModal
        open={openRemoveBankDetailsModal}
        onClose={handleRemoveBankDetailsModal}
        refresh={paymentMethodRefresh}
      />
    </>
  )
}
