import React, { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { AcknowledgeModal, FormField, Spacer, TextInput, Radio, Modal, ActionModal } from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { isValidWebsiteURL } from '@percent/utility'
import { PaymentMethod } from '@percent/admin-dashboard/api/types'

type UpdatePaymentMethodModalProps = {
  open: boolean
  onClose: VoidFunction
  refresh: VoidFunction
  paymentMethod?: PaymentMethod & {
    method: 'card' | 'cheque'
  }
}

export function UpdatePaymentMethodModal({ open, onClose, refresh, paymentMethod }: UpdatePaymentMethodModalProps) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { adminService } = useServices()
  const [errorDialogState, setErrorDialogState] = useState(false)

  const [{ isLoading, success }, { apiFunc }] = useMutation(
    adminService.updateOrganisationPaymentMethod,
    undefined,
    () => {
      setErrorDialogState(true)
    }
  )

  const initialValues = {
    method: paymentMethod?.method,
    paymentUrl: paymentMethod?.paymentUrl
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: () => {
      return Yup.object().shape({
        method: Yup.string().trim().oneOf(['card', 'cheque']).required(),
        paymentUrl: Yup.string()
          .nullable()
          .optional()
          .when('method', {
            is: 'card',
            then: isValidWebsiteURL('This must be a valid website payment URL')
              .trim()
              .nullable()
              .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Payment URL' }))
              .max(2000, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Payment URL' }))
              .required('Payment URL is required for card payment method')
          })
      })
    },
    onSubmit: async ({ method, paymentUrl }) => {
      if (method) {
        await apiFunc({
          payload:
            method === 'card'
              ? {
                  method,
                  paymentUrl: paymentUrl?.startsWith('https') ? paymentUrl : `https://${paymentUrl}`
                }
              : { method },
          id
        })
      }
    }
  })

  const { errors, values, touched, dirty, handleChange, handleBlur, handleSubmit, isValid, resetForm, setFieldValue } =
    formik

  const handleCloseModal = () => {
    onClose()
    refresh()
    resetForm()
  }

  const handleOnErrorClose = () => {
    setErrorDialogState(false)
  }

  const successOrErrorModal =
    success && !errorDialogState ? (
      <AcknowledgeModal
        result="positive"
        title={t('dialog.updatePaymentMethod.success.title')}
        description={t('dialog.updatePaymentMethod.success.description')}
        buttonText="Close"
        handleClose={handleCloseModal}
        viewTestId="success-payment-method-update-modal"
        buttonTestId="update-payment-method-success-button"
      />
    ) : (
      <AcknowledgeModal
        result="negative"
        title={t('dialog.updatePaymentMethod.error.title')}
        description={t('dialog.updatePaymentMethod.error.description')}
        buttonText="Close"
        handleClose={handleOnErrorClose}
        viewTestId="error-payment-method-update-modal"
        buttonTestId="update-payment-method-error-button"
      />
    )

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="update-payment-method-form-modal">
      {!success && !errorDialogState ? (
        <ActionModal
          title={t('typography.paymentMethod')}
          primaryButtonText={t('dialog.updatePaymentMethod.form.savePaymentMethod')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          variant={isValid && dirty ? 'primary' : 'secondary'}
          disabled={!(isValid && dirty && !isLoading)}
          loading={isLoading}
          handleClose={() => {
            onClose()
            resetForm()
          }}
          handleSubmit={handleSubmit}
          primaryBtnTestId="update-method-submit-button"
          secondaryBtnTestId="update-method-cancel-button"
        >
          <form onSubmit={handleSubmit}>
            <FormikProvider value={formik}>
              <>
                <FormField data-testid="cheque-button">
                  <Radio
                    name="method"
                    value="cheque"
                    label={t('typography.cheque')}
                    checked={values.method === 'cheque'}
                    onChange={e => setFieldValue('method', e.target.value)}
                  />
                </FormField>
                <Spacer size={4} axis="vertical" />
                <FormField data-testid="card-button">
                  <Radio
                    name="method"
                    value="card"
                    label={t('typography.card')}
                    checked={values.method === 'card'}
                    onChange={e => setFieldValue('method', e.target.value)}
                  />
                </FormField>
                {values.method === 'card' && (
                  <>
                    <Spacer size={4} axis="vertical" />
                    <FormField
                      label={t('typography.paymentUrl')}
                      necessity="required"
                      status={touched.paymentUrl && errors.paymentUrl ? 'danger' : 'default'}
                      statusMessage={errors.paymentUrl || ''}
                      data-testid="paymentUrl"
                    >
                      <TextInput
                        name="paymentUrl"
                        value={values.paymentUrl || ''}
                        placeholder={t('dialog.updatePaymentMethod.form.paymentUrl.placeholder')}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormField>
                  </>
                )}
              </>
            </FormikProvider>
          </form>
        </ActionModal>
      ) : (
        successOrErrorModal
      )}
    </Modal>
  )
}
